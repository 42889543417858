export enum SortPropertyEnum {
  RATING_DESC = 'rating',
  RATING_ASC = '-rating',
  TITLE_DESC = 'title',
  TITLE_ASC = '-title',
  PRICE_DESC = 'price',
  PRICE_ASC = '-price',
}

export type Sort = {
  name: string
  sortProperty: SortPropertyEnum
}

export type Category = {
  id: number
  name: string
}

export interface FilterSliceState {
  searchValue: string
  category: Category
  currentPage: number
  sort: Sort
}